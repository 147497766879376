require('./bootstrap');

import swal from 'sweetalert2';
window.Swal = swal;

import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgShare from 'lightgallery/plugins/share';
window.lightGallery = lightGallery;
window.lgThumbnail = lgThumbnail;
window.lgZoom = lgZoom;
window.lgShare = lgShare;

import 'datatables.net-bs5';

import '@selectize/selectize';

import Chart from 'chart.js/auto';
window.Chart = Chart;

import 'trix';


$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

require('./number_input_field');

window.addModelsFromSearch = function (figureId, projectId)
{
    let count_to_create = $('#add_figure_' + figureId).val();

    $.ajax({
        url: "/models/addModelFromSearch",
        type: "POST",
        data: {
            'figureId': figureId,
            'projectId': projectId,
            'count_to_create': count_to_create,
        },
        success: function (response) {
            if(response.status == 'redirect'){
                window.location.href = response.url;
            }

            if($('#userFigure_' + response.figure_id).length){
                $('#userFigure_' + response.figure_id + '_count').html(response.count);
            }else{
                $('#added_models').prepend(response.html);
            }
            console.log(response);
        },
        error: function (error) {
            console.log(error);
        }
    });
}

